<template>
    <div class="all-section-area">
        <MainHeader />
        <main class="main-content-wrap">
            <BreadCrumb mainTitle="Visor de curso" pageTitle="Visor de curso" />
            <AdminVisorCurso />
            <div class="flex-grow-1"></div>
            <FooterArea />
        </main>
    </div>
</template>

<script>
import MainHeader from '../Layouts/MainHeader'
import BreadCrumb from '../Common/BreadCrumb'
import AdminVisorCurso from '../Admin/VisorCurso'
import FooterArea from '../Layouts/FooterArea'

export default {
    name: 'AdminVisorCursoPage',
    components: {
        MainHeader,
        BreadCrumb,
        AdminVisorCurso,
        FooterArea,
    },
    mounted() {
        document.body.classList.add('body-bg-f8faff')
    },
}
</script>