<template>
    <div class="all-section-area">
        <MainHeader />
        <main class="main-content-wrap">
            <PageTitle mainTitle="Mi Crecimiento" pageTitle="Mi Crecimiento" />
            <MisCursos />
            <MisLogros />
            <FooterArea />
        </main>
    </div>
</template>

<script>
import MainHeader from '../Layouts/MainHeader'
import PageTitle from '../Common/PageTitle'
import MisCursos from '../Cursos/MisCursos'
import MisLogros from '../Cursos/MisLogros'
import FooterArea from '../Layouts/FooterArea'

export default {
    name: 'MisCursosPage',
    components: {
        MainHeader,
        PageTitle,
        MisCursos,
        MisLogros,
        FooterArea,
    },
}
</script>