import { createWebHistory, createRouter } from "vue-router"

//Admin
import AdminConfigPage from "../components/Pages/AdminConfigPage"
import AdminProductosPage from "../components/Pages/AdminProductosPage"
import AdminSessionsPage from "../components/Pages/AdminSessionsPage"
import AdminUsuariosPage from "../components/Pages/AdminUsuariosPage"
import AdminPacientesPage from "../components/Pages/AdminPacientesPage"
import AdminCursosPage from "../components/Pages/AdminCursosPage"
import AdminEditorCursoPage from "../components/Pages/AdminEditorCursoPage"
import AdminVisorCursoPage from "../components/Pages/AdminVisorCursoPage"
import AdminUsuariosCursosPage from "../components/Pages/AdminUsuariosCursosPage"

//Cursos
import MisCursosPage from "../components/Cursos/MisCursosPage"
import VisorCursoPage from "../components/Cursos/VisorCursoPage"

import SessionsPage from "../components/Pages/SessionsPage"
import ChatPage from "../components/Pages/ChatPage"
import ContactUsPage from "../components/Pages/ContactUsPage"
import InboxBoxPage from "../components/Pages/InboxBoxPage"
import LoginPage from "../components/Pages/LoginPage"
import LogoutPage from "../components/Pages/LogoutPage"
import RegisterPage from "../components/Pages/RegisterPage"
import ProfilePage from "../components/Pages/ProfilePage"
import EditProfilePage from "../components/Pages/EditProfilePage"
import ForgetPasswordPage from "../components/Pages/ForgetPasswordPage"
import ScreenLockPage from "../components/Pages/ScreenLockPage"
import ErrorPage from "../components/Pages/ErrorPage"
import AlertsPage from "../components/Pages/AlertsPage"
import AvatarSquarePage from "../components/Pages/AvatarSquarePage"
import AvatarRoundedPage from "../components/Pages/AvatarRoundedPage"
import AvatarRadiusPage from "../components/Pages/AvatarRadiusPage"
import NavigationPage from "../components/Pages/NavigationPage"
import BreadcrumbsPage from "../components/Pages/BreadcrumbsPage"
import TabsPage from "../components/Pages/TabsPage"
import ModalPage from "../components/Pages/ModalPage"
import ProgressBarPage from "../components/Pages/ProgressBarPage"
import AboutUsPage from "../components/Pages/AboutUsPage"
import EmptyPage from "../components/Pages/EmptyPage"
import CardDesignPage from "../components/Pages/CardDesignPage"
import NotificationsPage from "../components/Pages/NotificationsPage"
import ContentScrollBarPage from "../components/Pages/ContentScrollBarPage"
import LoadersPage from "../components/Pages/LoadersPage"
import FormLayoutsPage from "../components/Pages/FormLayoutsPage"
import ComposeMailPage from "../components/Pages/ComposeMailPage"
import ContactListPage from "../components/Pages/ContactListPage"
import ApexChartsPage from "../components/Pages/ApexChartsPage"
import GroupPage from "../components/Pages/GroupPage"
import TermsPage from "../components/Pages/TermsPage"

import PatientProfilePage from "../components/Pages/PatientProfilePage"
import ProfilePaymentsPage from "../components/Pages/ProfilePaymentsPage"
import ProfileCheckoutPage from "../components/Pages/ProfileCheckoutPage"

import OauthCalendy from "../components/Pages/OauthCalendy"

const routes = [
//Admin
  { path: "/admin/config", name: "AdminConfigPage", component: AdminConfigPage },
  { path: "/admin/cursos", name: "AdminCursosPage", component: AdminCursosPage },
  { path: "/admin/cursos/editar", name: "AdminEditorCursoPage", component: AdminEditorCursoPage },
  { path: "/admin/cursos/visor", name: "AdminVisorCursoPage", component: AdminVisorCursoPage },
  { path: "/admin/productos", name: "AdminProductosPage", component: AdminProductosPage },
  { path: "/admin/sesiones", name: "AdminSessionsPage", component: AdminSessionsPage },
  { path: "/admin/usuarios", name: "AdminUsuariosPage", component: AdminUsuariosPage },
  { path: "/admin/usuarios/cursos", name: "AdminUsuariosCursosPage", component: AdminUsuariosCursosPage },
  { path: "/admin/pacientes", name: "AdminPacientesPage", component: AdminPacientesPage },
//LOGIN
  { path: "/login", name: "LoginPage", component: LoginPage },
  { path: "/registro", name: "RegisterPage", component: RegisterPage },
  { path: "/salir", name: "LogoutPage", component: LogoutPage },
  { path: "/recuperar-password", name: "ForgetPasswordPage", component: ForgetPasswordPage, },
  { path: "/terminos-y-condiciones", name: "TermsPage", component: TermsPage },
  //USUARIO
  { path: "/perfil", name: "ProfilePage", component: ProfilePage },
  { path: "/perfil/editar", name: "EditProfilePage", component: EditProfilePage },
  { path: "/bloquear-pantalla", name: "ScreenLockPage", component: ScreenLockPage },
  { path: "/paciente/perfil", name: "PatientProfilePage", component: PatientProfilePage },
  { path: "/paciente/sesiones", name: "SessionsPage", component: SessionsPage },
  { path: "/perfil/pagos", name: "ProfilePaymentsPage", component: ProfilePaymentsPage },
  { path: "/perfil/checkout", name: "ProfileCheckoutPage", component: ProfileCheckoutPage },
  //CURSOS
  { path: "/cursos/mis-cursos", name: "MisCursosPage", component: MisCursosPage },
  { path: "/cursos/visor", name: "VisorCursoPage", component: VisorCursoPage },

  { path: "/chat", name: "ChatPage", component: ChatPage },
  { path: "/contact-us", name: "ContactUsPage", component: ContactUsPage },
  { path: "/inbox", name: "InboxBoxPage", component: InboxBoxPage },

  { path: "/:pathMatch(.*)*", name: "ErrorPage", component: ErrorPage },
  { path: "/alerts", name: "AlertsPage", component: AlertsPage },
  {
    path: "/avatar-square",
    name: "AvatarSquarePage",
    component: AvatarSquarePage,
  },
  {
    path: "/avatar-rounded",
    name: "AvatarRoundedPage",
    component: AvatarRoundedPage,
  },
  {
    path: "/avatar-radius",
    name: "AvatarRadiusPage",
    component: AvatarRadiusPage,
  },
  { path: "/navigation", name: "NavigationPage", component: NavigationPage },
  { path: "/breadcrumbs", name: "BreadcrumbsPage", component: BreadcrumbsPage },
  { path: "/tabs", name: "TabsPage", component: TabsPage },
  { path: "/modal", name: "ModalPage", component: ModalPage },
  { path: "/progress", name: "ProgressBarPage", component: ProgressBarPage },
  { path: "/about-company", name: "AboutUsPage", component: AboutUsPage },
  { path: "/empty", name: "EmptyPage", component: EmptyPage },
  { path: "/cards-design", name: "CardDesignPage", component: CardDesignPage },
  {
    path: "/notifications",
    name: "NotificationsPage",
    component: NotificationsPage,
  },
  {
    path: "/content-scroll-bar",
    name: "ContentScrollBarPage",
    component: ContentScrollBarPage,
  },
  { path: "/loaders", name: "LoadersPage", component: LoadersPage },
  {
    path: "/form-layouts",
    name: "FormLayoutsPage",
    component: FormLayoutsPage,
  },
  {
    path: "/compose-mail",
    name: "ComposeMailPage",
    component: ComposeMailPage,
  },
  {
    path: "/contact-list",
    name: "ContactListPage",
    component: ContactListPage,
  },
  { path: "/apexcharts", name: "ApexChartsPage", component: ApexChartsPage },
  { path: "/group", name: "GroupPage", component: GroupPage },
  { path: "/oauthcalendy", name: "OauthCalendy", component: OauthCalendy },
]

const router = createRouter({
  history: createWebHistory(),
  linkExactActiveClass: "active",
  mode: "history",
  routes,
  scrollBehavior() {
    return { top: 0, behavior: "smooth" }
  },
})

router.beforeEach((to, from, next) => {
  if (to.path == '/') {
    router.push('/login')
    next()
  }
  if (to.path != '/login' && to.path != '/registro' && to.path != '/salir' && to.path != '/recuperar-password' && to.path != '/terminos-y-condiciones') {
    console.log('RUTAS')
    if (!localStorage.user) {
      //window.location.assign('http://localhost:8081/login')
      router.push('/login')
      localStorage.removeItem('screenLocked')
    }
  }
  if (localStorage.screenLocked) {
    next(false)
    return ''
  }
  next()
})

export default router
