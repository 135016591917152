<template>
    <div class="box-con-area">
        <div class="container-fluid">
            <div class="card-box-style">

                <h4>Logros</h4>
                <div class="col-lg-4 col-sm-6" v-for="(logro, index) in logros" :key="index">
                    <div class="card card-box mb-24">
                        <div class="card-body">
                            <p class="card-statics"><span class="badge">{{ format_date(logro.created_at) }}</span></p>
                            <h5 class="card-title"><i class="bx bxs-star"></i>{{logro.texto_logro}}</h5>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment'

export default {
    name: 'LogrosContent',
    data() {
        return {
            logros: {},
        }
    },
    mounted() {
        let loader= this.$loading.show({ canCancel: false })
        axios
            .get(this.$backendUrl + '/me/info/logros', { headers: { token: localStorage.userToken } })
            .then(response => {
                this.logros = response.data.data
                loader.hide()
            }, error => {
                console.log(error.response.data.description)
                loader.hide()
            })
    },
    methods: {
        link: function (id) {
            return '/logros/visor?id=' + btoa(id)
        },
        format_date(value) {
            if (value) {
                return moment(String(value)).format('YYYY-MM-DD')
            }
        },
    },
}
</script>

<style lang="scss">
.card-box {
    h5 {
        font-weight: 500;
        font-size: 16px;
        i {
           color: #1765fd; 
        }
    }
}

.card-statics {
    .badge {
        background-color: #1765fd;
    }

    font-size: 12px;
}
</style>