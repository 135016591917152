<template>
    <div class="email-content-area mt-20">
        <div class="container-fluid">
            <div class="sidebar-left">
                <div class="sidebar">
                    <div class="sidebar-content d-flex email-sidebar scroll-bar">
                        <div class="email-menu">

                            <div class="list-group list-group-messages">
                                <div to="/inbox" class="list-group-item d-flex justify-content-between"
                                    @click="verLista('nuevos')">
                                    <div class="icon">
                                        <img class="me-2" src="../../assets/images/icon/inbox.svg" alt="inbox">
                                        <img class="d-none" src="../../assets/images/icon/inbox-color.svg"
                                            alt="inbox-color">Nuevos
                                    </div>
                                </div>

                                <div to="/trash" class="list-group-item d-flex justify-content-between"
                                    @click="verLista('leidos')">
                                    <div class="icon">
                                        <img class="me-2" src="../../assets/images/icon/trash-m.svg" alt="inbox">
                                        <img class="d-none" src="../../assets/images/icon/trash-color.svg"
                                            alt="trash-color">Leídos
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="content-right">
                <div class="email-area">
                    <div class="email-list-wrapper">
                        <div class="email-list">
                            <div class="email-list-header d-flex align-items-center">
                                <!--
                                <div class="header-left w-100">
                                    <form @submit.prevent>
                                        <img src="../../assets/images/icon/search-normal.svg" alt="search-normal">
                                        <input type="text" class="form-control" placeholder="Buscar">
                                    </form>
                                </div>

                                <ul class="header-right d-flex justify-content-between align-items-center">
                                    <li>
                                        <button>
                                            <img src="../../assets/images/icon/reload.svg" alt="reload">
                                        </button>
                                    </li>
                                </ul>
                                //-->
                            </div>

                            <ul class="list-wrapper scroll-bar" v-if="viendo == 'nuevos'">
                                <li class="email-list-item" v-for="(mensaje, index) in mensajes" :key="index"
                                    @click="marcarLeido(mensaje.id_usuario_notificacion)">
                                    <div v-if="mensaje.leido == 0" style="display: contents;">
                                        <div class="email-list-actions mr-3">
                                            <!--<div class="checkbox">
                                                <input class="form-check-input" type="checkbox">
                                            </div>//-->
                                            <span class="favorite">
                                                <img src="../../assets/images/icon/eye.svg" alt="leido">
                                            </span>
                                        </div>

                                        <a href="#" class="email-list-detail">
                                            <div>
                                                <span v-if="mensaje.id_usuario_logro > 0" class="from">Logros</span>
                                                <span v-if="mensaje.id_notificacion > 0" class="from">Talencer</span>
                                                <p class="mb-0 msg" v-if="mensaje.id_usuario_logro > 0">{{
                                                    mensaje.texto_logro }}</p>
                                                <p class="mb-0 msg" v-if="mensaje.id_usuario_logro > 0">{{
                                                    mensaje.texto_corto }}</p>
                                            </div>

                                            <span class="date d-block">{{ format_date(mensaje.created_at) }}</span>
                                        </a>
                                    </div>
                                </li>
                            </ul>

                            <ul class="list-wrapper scroll-bar" v-if="viendo == 'leidos'">
                                <li class="email-list-item" v-for="(mensaje, index) in papelera" :key="index">
                                    <div style="display: contents;">
                                        <div class="email-list-actions mr-3">
                                            <div class="checkbox">
                                                <input class="form-check-input" type="checkbox">
                                            </div>
                                        </div>

                                        <a href="#" class="email-list-detail">
                                            <div>
                                                <span v-if=" mensaje.id_usuario_logro> 0" class="from">Logros</span>
                                                <span v-if="mensaje.id_notificacion > 0" class="from">Talencer</span>
                                                <p class="mb-0 msg" v-if="mensaje.id_usuario_logro > 0">{{
                                                    mensaje.texto_logro }}</p>
                                                <p class="mb-0 msg" v-if="mensaje.id_usuario_logro > 0">{{
                                                    mensaje.texto_corto }}</p>
                                            </div>

                                            <span class="date d-block">{{ format_date(mensaje.created_at) }}</span>
                                        </a>
                                    </div>
                                </li>
                            </ul>

                            <!--
                            <div class="email-list-footer d-flex justify-content-between align-items-center">
                                <p class="mb-0">Show: 1 - 10 of 590</p>

                                <div class="email-pagination-btn">
                                    <button type="submit" class="email-pagination-prev d-inline-block">
                                        <i class="ri-arrow-left-line"></i>
                                    </button>
                                    <button type="submit" class="email-pagination-next d-inline-block">
                                        <i class="ri-arrow-right-line"></i>
                                    </button>
                                </div>
                            </div>
                            //-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'

export default {
    name: 'InboxContent',
    data() {
        return {
            mensajes: {},
            papelera: {},
            viendo: 'nuevos',
        }
    },
    mounted() {
        let loader = this.$loading.show({ canCancel: false })
        axios
            .get(this.$backendUrl + '/me/info/notificaciones', { headers: { token: localStorage.userToken } })
            .then(response => {
                this.mensajes = response.data.data
                loader.hide()
            }, error => {
                console.log(error.response.data.description)
                loader.hide()
            })
    },
    methods: {
        verLista(modo) {
            this.viendo = modo
            if (modo == 'nuevos') {
                let loader = this.$loading.show({ canCancel: false })
                axios
                    .get(this.$backendUrl + '/me/info/notificaciones/nuevas', { headers: { token: localStorage.userToken } })
                    .then(response => {
                        this.mensajes = response.data.data
                        loader.hide()
                    }, error => {
                        console.log(error.response.data.description)
                        loader.hide()
                    })
            } else {
                let loader = this.$loading.show({ canCancel: false })
                axios
                    .get(this.$backendUrl + '/me/info/notificaciones/leidas', { headers: { token: localStorage.userToken } })
                    .then(response => {
                        this.papelera = response.data.data
                        loader.hide()
                    }, error => {
                        console.log(error.response.data.description)
                        loader.hide()
                    })
            }
        },
        marcarLeido(id) {
            let loader = this.$loading.show({ canCancel: false })
            axios
                .put(this.$backendUrl + '/me/info/notificaciones',
                    { 'id_usuario_notificacion': id },
                    { headers: { token: localStorage.userToken } })
                .then(response => {
                    console.log(response)
                    axios
                        .get(this.$backendUrl + '/me/info/notificaciones', { headers: { token: localStorage.userToken } })
                        .then(response => {
                            this.mensajes = response.data.data
                        }, error => {
                            console.log(error.response.data.description)
                        })
                    loader.hide()
                }, error => {
                    console.log(error.response.data.description)
                    loader.hide()
                })
        },
        format_date(value) {
            if (value) {
                return moment(String(value)).format('YYYY-MM-DD')
            }
        },
    },
}
</script>

<style lang="scss">
.email-content-area {
    .sidebar-left {
        float: left;
        .sidebar {
            .sidebar-content {
                background-color: var(--white-color);
                box-shadow: var(--box-shadow);
                padding: 25px;
                width: 400px;
                height: calc(100vh - 201px);
				border-radius: 20px;
				margin-bottom: 24px;
                .email-menu {
                    width: 100%;
                    .btn {
                        border: none;
                        padding: 12px 20px 11px;
                        position: relative;
                        transition: var(--transition);
                        font: {
                            size: var(--font-size);
                            weight: 600;
                        };
                        i {
                            font-size: 20px;
                            position: absolute;
                            margin: {
                                left: -23px;
                            };
                        }
                    }
                    .btn-primary {
                        background-color: #5C31D6;
                        width: 100%;
                        border-radius: 15px;
                        padding: 19px 20px;
                    }
                    .list-group-messages {
						border-bottom: 1px solid #EBF1FF;
						padding-bottom: 20px;
                        .list-group-item {
                            text-decoration: none;
                            padding: 15px 30px;
                            background-color: var(--white-color);
                            border: none !important;
                            color: var(--body-color);
                            border-radius: 0 !important;
                            font: {
                                size: 16px;
                                weight: 500;
                            };
                            i {
                                position: absolute;
                                left: 0;
                                transition: var(--transition);
                                top: 0;
                                font-size: 15px;
								font-weight: normal;
                            }
							span {
								color: #5C31D6;
							}
							.me-2 {
								margin-right: 20px;
							}
							&:nth-child(1) {
								img {
									top: 2px !important;
								}
							}
							&:nth-child(2) {
								img {
									top: -2px !important;
									position: relative;
								}
								.icon .d-none {
									top: 0 !important;
								}
							}
							&:nth-child(3) {
								img {
									top: -1px !important;
									position: relative;
								}
								.icon .d-none {
									top: 0 !important;
								}
							}
							&:nth-child(5) {
								img {
									top: -2px !important;
									position: relative;
								}
								.icon .d-none {
									top: 0 !important;
								}
							}
                            &:last-child {
                                margin-bottom: 0;
                            }
                            &.active {
                                background: #EBF1FF 0% 0% no-repeat padding-box;
                                color: #1765FD;
								border-radius: 15px !important;
								.icon {
									position: relative;
									.d-none {
										position: absolute;
										top: 0;
										left: 0;
										display: block !important;
									}
									.me-2 {
										opacity: 0;
									}
								}
								
                            }
                        }
                    }
                    .list-group-label {
                        letter-spacing: 1px;
                        color: #828D99;
                        margin: {
                            top: 25px;
                            bottom: 15px;
                        };
                        font: {
                            size: 14px;
                            weight: 600;
                        };
                    }
					.tags {
						color: var(--heading-color);
						font-size: 18px;
						font-weight: 600;
					}
                    .list-group-labels {
                        .list-group-item {
                            text-decoration: none;
                            padding: {
                                top: 0;
                                left: 0;
                                bottom: 0;
                                right: 18px;
                            };
                            margin-bottom: 12px;
                            background-color: var(--white-color);
                            border: none !important;
                            color: var(--heading-color);
                            border-radius: 0 !important;
							padding-left: 30px;
							margin-left: 30px;
                            font: {
                                size: 16px;
                                weight: 500;
                            };
							color: #67748E;
                            &::before {
                                width: 12px;
                                height: 12px;
                                content: '';
                                position: absolute;
                                left: 0;
                                top: 6px;
                                background-color: #1765FD;
								border-radius: 50px;
                            }
                            &:last-child {
                                margin-bottom: 0;
                            }
                            &.active {
                                background-color: var(--white-color);
                                color: var(--main-color);
                            }
                            &:hover {
                                color: var(--main-color);
                            }
                            &:nth-child(2) {
                                &::before {
                                    background-color: #4FCB8D;
                                }
                            }
                            &:nth-child(3) {
                                &::before {
                                    background-color: #FEC107;
                                }
                            }
                            &:nth-child(4) {
                                &::before {
                                    background-color: #5C31D6;
                                }
                            }
                            &:nth-child(5) {
                                &::before {
                                    background-color: #FE5957;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .content-right {
        float: right;
        width: calc(100% - 424px);
		margin-bottom: 24px;
        .email-list-wrapper {
            .email-list {
                background-color: var(--white-color);
                box-shadow: var(--box-shadow);
                border-radius: 20px;
                .email-list-header {
                    padding: 30px 0;
					margin-left: 30px;
					margin-right: 30px;
                    border-bottom: 1px solid #EBF1FF;
                   
                    .header-left {
                        position: relative;

                        img {
                            position: absolute;
                            left: 15px;
                            color: #71738d;
                            margin-bottom: 0;
                            top: 14px;
                        }
                        .form-control {
                            height: 48px;
                            border-radius: 30px;
                            background-color: #eef5f9;
                            border: none;
                            color: var(--heading-color);
                            padding: 2px 15px 0 51px;
                            transition: var(--transition);
                            box-shadow: unset;
							width: 355px;
							&::placeholder {
								color: #67748E;
								font-size: 14px;
							}
                            font: {
                                size: 14px;
                                weight: 400;
                            };
                            &:focus {
                                box-shadow: unset;
                                background-color: #eef5f9;
								border-color: var(--main-color);
                                &::placeholder {
                                    color: transparent;
                                }
                            }
                            &::placeholder {
                                color: #a8a8a8;
                                transition: var(--transition);
                            }
                        }
                    }
					.header-right {
						padding-left: 0;
						margin-bottom: 0;
						list-style-type: none;
						li {
							display: inline-block;
							margin-right: 15px;
							&:last-child {
								margin-right: 0;
							}
							button {
								background-color: transparent;
								background-color: #5C31D6;
								width: 45px;
								height: 45px;
								text-align: center;
								border-radius: 10px;
							}
							.form-select {
								height: 45px;
								border-color: #BCC2CF;
								cursor: pointer;
								font-size: 12px;
								color: #292D32;
								width: 100px;
								border-radius: 10px;
								&:focus {
									box-shadow: none;
								}
							}
						}
					}
                }
                .list-wrapper {
                    height: calc(100vh - 343px);
                    padding: 0 30px;
                    margin-bottom: 0;
                    list-style-type: none;
                    &.scroll-bar {
                        overflow-x: scroll !important;
                        &::-webkit-scrollbar {
                            height: 4px;
                        }
                    }

                    .email-list-item {
                        display: flex;
                        align-items: center;
                        border-bottom: 1px solid #e8ebf1;
                        padding: 16.1px 15px;
                        width: 100%;
                        position: relative;
                        background-color: var(--white-color);
                        font-size: var(--font-size);
                        transition: var(--transition);

                        &:last-child {
                            border-bottom: none;
                        }
                        .email-list-actions {
                            .checkbox {
                                position: relative;
                                top: -4px;
								display: inline-block;
                                margin-right: 10px;
								.form-check-input {
									width: 20px;
									height: 20px;
									border-color: #1765FD;
									&:focus {
										box-shadow: none;
									}
								}
                            }
                            .favorite {
                                display: inline-block;
                                font-size: 20px;
                                cursor: pointer;
                                color: #C7CFD6;
                                margin: {
                                    top: 0;
                                    bottom: -5px;
									right: 10px;
                                };
                                img {
                                    filter: invert(45%) sepia(93%) saturate(1909%) hue-rotate(162deg) brightness(95%) contrast(101%);
                                }
                                &.active {
                                    color: #FDAC41;

                                    i {
                                        &::before {
                                            content: "\ed99";
                                        }
                                    }
                                }
                            }
                        }
                        .email-list-detail {
                            text-decoration: none;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            flex-grow: 1;
                            flex-wrap: wrap;
                            position: relative;

                            div {
                                position: relative;
                                padding-left: 15px;
                                margin-top: 0;
                            }
                            img {
                                position: absolute;
                                left: 0;
                                top: 0;
                                width: 50px;
                                height: 50px;
                                border-radius: 50%;
                            }
                            .from {
                                color: var(--heading-color);
								margin-right: 10px;
								width: 300px;
								display: inline-block;
                                font: {
                                    size: 14px;
                                    weight: 600;
                                };
                            }
                            .msg {
                                color: #71738d;
								display: block;
                            }
                            .date {
                                color: var(--heading-color);
                                font-weight: 600;
								font-size: 14px;
                            }
                        }
                        &.read {
                            background-color: #f7f7f7;

                            .email-list-detail {
                                .from {
                                    color: #616161;
                                }
                                .msg {
                                    color: #71738d;
                                }
                                .date {
                                    color: #616161;
                                }
                            }
                        }
						&:hover {
                            background-color: #F8FAFF;
                            transition: var(--transition);
                            z-index: 1;
                        }
                    }
                }
                .email-list-footer {
                    border-top: 1px solid #e8ebf1;
                    padding: 15px 20px;

                    p {
                        color: #71738d;
                    }
                    .email-pagination-btn {
                        margin-bottom: -5px;

                        button {
                            width: 34px;
                            height: 34px;
                            background-color: #f3f3f3;
                            transition: var(--transition);
                            position: relative;
                            border: none;
                            padding: 0;
                            font-size: 18px;
                            border-radius: 5px;
                            margin-left: 5px;

                            i {
                                position: absolute;
                                left: 0;
                                right: 0;
                                top: 50%;
                                transform: translateY(-50%);
                            }
                            &:hover {
                                color: var(--white-color);
                                background-color: var(--main-color);
                            }
                            &.email-pagination-prev {
                                border-radius: 5px 0 0 5px;
                            }
                            &.email-pagination-next {
                                border-radius: 0 5px 5px 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Max width 767px */
@media only screen and (max-width: 767px) {
    .email-content-area {
        .sidebar-left {
            float: unset;
            .sidebar {
                .sidebar-content {
                    width: auto;
                }
            }
            
        }
        .content-right {
            float: unset;
            width: unset;
            .email-list-wrapper {
                .email-list {
                    .email-list-header {
                        display: block !important;
                        padding: 20px 0;
                        .header-right {
                            li {
                                .form-select {
                                    width: 100%;
                                }
                            }
                        }
                    }
                    .list-wrapper {
                        .email-list-item {
                            padding: 16.1px 0;
                            .email-list-detail {
                                .form {
                                    width: auto;
                                }
                                .msg {
                                    margin-left: 0;
                                }
                                div {
                                    padding-left: 0;
                                }
                            }
                        }
                    }
                }
                
            }
            .form-control {
                width: 100% !important;
                margin-bottom: 20px;
            }
        }
    }
}

/* Min width 576px to Max width 767px */
@media only screen and (min-width: 576px) and (max-width: 767px) {
    .email-content-area {
        .content-right {
            .email-list-wrapper {
                .email-list {
                    .list-wrapper {
                        .email-list-item {
                            .email-list-actions {
                                .favorite {
                                    display: block;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Min width 768px to Max width 991px */
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .email-content-area {
        .sidebar-left {
            float: unset;
            .sidebar {
                .sidebar-content {
                    width: auto;
                }
            }
            
        }
        .content-right {
            float: unset;
            width: unset;
            .email-list-wrapper {
                .email-list {
                    .email-list-header {
                        display: block !important;
                        padding: 20px 0;
                        .header-right {
                            li {
                                .form-select {
                                    width: 100%;
                                }
                            }
                        }
                    }
                    .list-wrapper {
                        .email-list-item {
                            padding: 16.1px 0;
                            .email-list-detail {
                                .from {
                                    width: auto;
                                }
                                .msg {
                                    margin-left: 0;
                                }
                                div {
                                    padding-left: 0;
                                }
                            }
                        }
                    }
                }
                
            }
            .form-control {
                width: 100% !important;
                margin-bottom: 20px;
            }
        }
    }
}

/* Min width 992px to Max width 1199px */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .email-content-area {
        .sidebar-left {
            .sidebar {
                .sidebar-content {
                    width: 290px;
                }
            }
        }
        .content-right {
            width: calc(100% - 300px);
            .email-list-wrapper {
                .email-list {
                    .email-list-header {
                        .header-left {
                            margin-right: 20px;
                            .form-control {
                                width: 100%;
                            }
                        }
                    }
                }
                .email-list .list-wrapper .email-list-item {
                    padding: 16.1px 0;
                    .email-list-detail {
                        .from {
                            width: auto;
                        }
                        .msg {
                            margin-left: 0;
                        }
                    }
                    .email-list-detail {
                        div {
                            padding-left: 0;
                        }
                    }
                }
            }
        }
    }
}

/* Min width 1200px to Max width 1320px */
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .email-content-area {
        .sidebar-left {
            .sidebar {
                .sidebar-content {
                    width: 290px;
                }
            }
        }
        .content-right {
            width: calc(100% - 300px);
            .email-list-wrapper {
                .email-list {
                    .email-list-header {
                        .header-left {
                            margin-right: 20px;
                            .form-control {
                                width: 100%;
                            }
                        }
                    }
                }
                .email-list .list-wrapper .email-list-item {
                    padding: 16.1px 0;
                    .email-list-detail {
                        .from {
                            width: auto;
                        }
                        .msg {
                            margin-left: 0;
                        }
                        
                        div {
                            padding-left: 0;
                        }
                    }
                }
            }
        }
    }
}
</style>