<template>
    <div class="navigation-area">
        <div class="container-fluid">
            <div class="card-box-style">
                <div class="others-title">
                    <h3>Default Navigation</h3>
                </div>

                <nav class="navbar navbar-expand-lg bg-light">
                    <div class="container-fluid">
                        <a class="navbar-brand" href="#">Navbar</a>
                        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>

                        <div class="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                                <li class="nav-item">
                                    <a class="nav-link active" aria-current="page" href="#">Home</a>
                                </li>

                                <li class="nav-item">
                                    <a class="nav-link" href="#">Link</a>
                                </li>

                                <li class="nav-item dropdown">
                                    <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Dropdown
                                    </a>
                                    <ul class="dropdown-menu">
                                        <li><a class="dropdown-item" href="#">Action</a></li>
                                        <li><a class="dropdown-item" href="#">Another action</a></li>
                                        <li><hr class="dropdown-divider"></li>
                                        <li><a class="dropdown-item" href="#">Something else here</a></li>
                                    </ul>
                                </li>

                                <li class="nav-item">
                                    <a class="nav-link disabled">Disabled</a>
                                </li>
                            </ul>
                            <form @submit.prevent class="d-flex" role="search">
                                <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
                                <button class="btn btn-outline-success" type="submit">Search</button>
                            </form>
                        </div>
                    </div>
                </nav>
            </div>

            <div class="card-box-style">
                <div class="others-title">
                    <h3>Custom Navigation</h3>
                </div>

                <nav class="navbar navbar-expand-lg bg-light navbar-custom-style">
                    <div class="container-fluid">
                        <a class="navbar-brand" href="#">
                            <img src="../../assets/images/talencer_fondo_pequeno.png" alt="main-logo">
                        </a>
                        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>

                        <div class="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul class="navbar-nav m-auto mb-2 mb-lg-0">
                                <li class="nav-item">
                                    <router-link class="nav-link active" aria-current="page" to="/">Home</router-link>
                                </li>

                                <li class="nav-item">
                                    <router-link class="nav-link" to="/about-company">About Us</router-link>
                                </li>

                                <li class="nav-item dropdown">
                                    <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Pages
                                    </a>

                                    <ul class="dropdown-menu">
                                        <li><router-link class="dropdown-item" to="/blog">Blog</router-link></li>
                                        <li><router-link class="dropdown-item" to="/blog-details">Blog Details</router-link></li>
                                        <li><router-link class="dropdown-item" to="/friends">Friends</router-link></li>
                                        <li><router-link class="dropdown-item" to="/inbox">Inbox</router-link></li>
                                        <li><router-link class="dropdown-item" to="/login">Log In</router-link></li>
                                    </ul>
                                </li>

                                <li class="nav-item">
                                    <a class="nav-link">Contact Us</a>
                                </li>
                            </ul>
                            <form @submit.prevent class="d-flex" role="search">
                                <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
                                <button class="btn btn-outline-success" type="submit">Search</button>
                            </form>
                        </div>
                    </div>
                </nav>
            </div>

            <div class="card-box-style">
                <div class="others-title">
                    <h3>Custom Navigation Without Search Bar</h3>
                </div>

                <nav class="navbar navbar-expand-lg bg-light navbar-custom-style">
                    <div class="container-fluid">
                        <a class="navbar-brand" href="#">
                            <img src="../../assets/images/talencer_fondo_pequeno.png" alt="main-logo">
                        </a>
                        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>

                        <div class="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                                <li class="nav-item">
                                    <router-link class="nav-link active" aria-current="page" to="/inbox">Home</router-link>
                                </li>

                                <li class="nav-item">
                                    <router-link class="nav-link" to="/about-company">About Us</router-link>
                                </li>

                                <li class="nav-item dropdown">
                                    <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Pages
                                    </a>

                                    <ul class="dropdown-menu">
                                        <li><router-link class="dropdown-item" to="/blog">Blog</router-link></li>
                                        <li><router-link class="dropdown-item" to="/blog-details">Blog Details</router-link></li>
                                        <li><router-link class="dropdown-item" to="/friends">Friends</router-link></li>
                                        <li><router-link class="dropdown-item" to="/inbox">Inbox</router-link></li>
                                        <li><router-link class="dropdown-item" to="/login">Log In</router-link></li>
                                    </ul>
                                </li>

                                <li class="nav-item">
                                    <router-link to="/contact-us" class="nav-link">Contact Us</router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NavigationContent'
}
</script>

<style lang="scss">
.navbar-custom-style {
	background-color: var(--white-color) !important;
	box-shadow: var(--box-shadow);
	.dropdown-menu {
		border: none;
		box-shadow: var(--box-shadow);
		padding-top: 0;
		.dropdown-item {
			&:hover {
				background-color: transparent;
				color: var(--main-color);
			}
		}
	}
}
</style>